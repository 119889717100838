import React, { useEffect, useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import {
  defaultFilterValue,
  useFilters,
  filterItemByAttribute,
  loadMoreVisibility,
  handleLoadMore,
} from '../helpers/filtering';
import { Layout } from '../components/layout';
import FilterArrow from '../images/filter-arrow.inline.svg';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const Publications = ({ data }) => {
  // eslint-disable-next-line prefer-destructuring
  const publications = data.publications;
  const items = data.allDatoCmsSinglePublication.edges;
  const increment = 16;
  const years = Array.from(new Set(items.map((item) => item.node.year)));
  const yearRef = useRef(null);
  const categories = Array.from(new Set(items.map((item) => item.node.category.title)));
  const categoryRef = useRef(null);

  const [activeItems, setActiveItems] = useState(items);
  const [visibleLimit, setVisibleLimit] = useState(increment);

  const [filters, setFilters] = useState({
    category: defaultFilterValue,
    year: defaultFilterValue,
  });

  const handleFilterItems = (publications) => {
    const filteredPublications = publications
      .filter((publication) => filterItemByAttribute(filters.category, publication, 'category'))
      .filter((publication) => filterItemByAttribute(filters.year, publication, 'year'));
    setVisibleLimit(increment);
    setActiveItems(filteredPublications);
  };

  const handleFilters = () => {
    setFilters({
      year: yearRef.current.value || defaultFilterValue,
      category: categoryRef.current.value || defaultFilterValue,
    });
  };

  useFilters(filters, handleFilterItems, items);

  useEffect(() => {
    handleFilters();
  }, []);

  return (
    <Layout bgcolor={publications.backgroundColor} wrapperClass="publications" accentColor={publications.accentColor}>
      <HelmetDatoCms seo={publications.seoMetaTags} />
      <div className="main-block">
        <div className="main-title col">
          <h1 className="gothic--large large-title">{publications.title}</h1>
        </div>
        <div className="main-image col">
				<Img fluid={publications.featuredImage.fluid} alt={publications.featuredImage.alt} className="featured-title-image" />
          <p
            className="caption"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: publications.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: publications.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={publications.accentColor} />
          </div>
        </div>
      </div>
      <div className="publications-list container row">
        <div className="col-sm-14 col-xs-6 col filter-row">
          <div className="filter-name">Publications</div>
          <div className="filters">
            {years.length > 0 && (
            <div className="filter">
              <span>Year:</span>
              <div className="custom-filter-select">
                <span>{filters.year}</span>
                <select
                  ref={yearRef}
                  id="publication-year"
                  onChange={() => { handleFilters(); }}
                >
                  <option value={defaultFilterValue}>{defaultFilterValue}</option>
                  {years.map((yearValue) => (
                    <option key={yearValue} value={yearValue}>{yearValue}</option>
                  ))}
                </select>
                <FilterArrow />
              </div>
            </div>
            )}
            {categories.length > 0 && (
              <div className="filter">
                <span>Type of publication:</span>
                <div className="custom-filter-select">
                  <span>{filters.category}</span>
                  <select
                    ref={categoryRef}
                    onChange={() => { handleFilters(); }}
                  >
                    <option value={defaultFilterValue}>{defaultFilterValue}</option>
                    {categories.map((categoryValue) => (
                      <option key={categoryValue} value={categoryValue}>{categoryValue}</option>
                    ))}
                  </select>
                  <FilterArrow />
                </div>
              </div>
            )}
          </div>
        </div>
        {activeItems.length > 0 && (
          <>
            <div className="col-sm-2 col-xs-6 col">
              <span className="sidebar-text filter">{publications.sideHeading}</span>
            </div>
            <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
              {activeItems.map(({ node: item }, i) => (
                <div className="tile col" key={item.id} style={{ display: `${loadMoreVisibility(i, visibleLimit)}` }}>
                  <Link to={`/publications/${item.slug}`} className="item-tile">
                    {item.featuredImage && (
											<ImageFadeIn
												src={item.featuredImage.url}
												alt={item.featuredImage.alt}
												className="tile-image"
											/>  
                    )}
                    <div className="tile-text-wrap">
                      <h4
                        className="sans--regular item-title"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item.formattedTitle,
                        }}
                      />
                      <span className="publication-year">{item.year}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="container col row">
        <div className="offset-sm-2 col-sm-12 col-xs-6 col row spacing-0">
          {activeItems.length > visibleLimit && <button onClick={() => { handleLoadMore(setVisibleLimit, visibleLimit, increment); }} type="button" aria-label="Load more" className="btn show-more">Load more</button>}
        </div>
      </div>
      <div className="col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
        <DividerBlock accentColor={publications.accentColor} />
      </div>
    </Layout>
  );
};
Publications.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Publications;

export const query = graphql`
  query PublicationsQuery {
    publications: datoCmsPublication {
      backgroundColor
      accentColor {
        hex
      }
      introduction
      title
      sideHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      featuredImage {
				fluid(maxHeight: 450) {
          ...GatsbyDatoCmsSizes_noBase64
        }  
				alt    
			}
    }
    allDatoCmsSinglePublication(
      sort: {
        fields: [year],
        order: DESC
      },
      filter: {meta: {isValid: {eq: true}, status: {ne: "draft"}}}
    ) {
      edges {
        node {
          id
          year
          slug
          formattedTitle
          featuredImage {
						url(imgixParams: { h: "450", ar: "1:1", fit: "crop", crop: "focalpoint" })
						alt
          }
          category {
            title
          }
          model {
              name
          }
        }
      }
    }
  }
`;
